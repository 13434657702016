<template>
  <div class="home">

    <div class="page-container">

      <span class="title-header">
        Classement

        <span class="title-count">(Vous êtes {{ pos }} éme avec {{ Math.round(userData.boule2noel) }} Boules)</span>
      </span>


      <div class="requests-list">

        <div class="request" v-for="(userInfo, i) in usersList" :key="userInfo.id">

          <template class="request">

            <div class="title-id">
              #{{i+1}} {{userInfo.userName}}
            </div>

            <div class="title-name prefix">
              #{{i+1}}
            </div>

            <div class="title-name">
              {{userInfo.userName}}
            </div>

            <div class="price">
              <b>{{ new Intl.NumberFormat().format(userInfo.boule2noel) }}</b> Boules
            </div>

          </template>

  

        </div>        
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { auth, db } from '../firebase'
import { mapState } from 'vuex'
import moment from 'moment-timezone'

moment.locale('fr');  

export default {
  name: 'Classement',

  firestore() {
    return {
      rewards: db.collection('rewards').orderBy('buyAt', 'desc'),
      games: db.collection('games'),
      users: db.collection('users').orderBy('boule2noel', 'desc').limit(51)
    }
  },

  data () {
    return {
      rewards: [],
      games: [],
      users: [],

      moment
    }
  },

  computed: {
    ...mapState(['user']),

    usersList () {
      const data = this.users
      return data.filter(x => x.userName !=='alxios').splice(0, 100);
    },

    userData() {
      return this.users.find(x => x.userId === this.user.id) || {}
    },

    pos () {
      const data = this.users.filter(x => x.userName !=='alxios')
      const current = data.find( x => x.userId === this.userData.userId)

      return data.indexOf(current) + 1
    }
  }

}

</script>

<style lang="scss" scoped>

.message-info {
  width: 100%;
  text-align: center;

  margin-top: 24px;

  background: #202225;
  padding: 14px 18px;
  border-radius: 5px;

  svg {
    margin-right: 8px;
  }
}

@media screen and (max-width: 900px) {
  .title-header {
    align-items: flex-start !important;
    text-align: left !important;
    flex-direction: column !important;

    .title-count {
      margin-top: 12px;
    }
  }

  .home {
    max-height: calc(100vh) !important;
    padding-bottom: 84px;
  }

  .request {
    flex-direction: column !important;
    height: unset !important;

    div {
      margin: 0 !important;
      text-align: left !important;
      width: 100% !important;
      height: 24px;

      margin-left: 24px !important;
    }

    .title-id {
      margin-top: 8px !important;
      margin-bottom: 4px !important;
      display: block !important;
    }

    .price {
      margin-bottom: 4px !important;
      margin-right: 0px;
    }

    .title-name {
      display: none !important;
    }

  }
}

.title-id {
  display: none !important;
}

.requests-list {
  margin-top: 42px;
}

.request {
  height: 64px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 12px;

  background: #171717;

  border-radius: 5px;

  overflow: hidden;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .title-name {
    margin-left: 24px;
    margin-right: 24px;

    width: 100%;
    color: white;

    &.prefix {
      margin-right: 0;
      width: 24px;
    }
  }

  .buyat {
    font-weight: 300;

    width: 25%;

    b {
      color: white;
      text-transform: lowercase;
      margin-left: 6px;
    }
  }

  .price {
    text-align: right;
    width: 55%;

    margin-right: 24px;

    b {
      color: white;
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  .status {
    width: 30%;
    padding-right: 32px;
    text-align: right;
  }

  .img {
    width: 84px;
    height: 84px;

    background-position: center;
    background-size: cover;

    border-right: #202225 1px solid;
    margin-right: 18px;
  }

}

.home {
  max-height: calc(100vh);
  height: 100%;
  overflow: auto;
}

.title-header {
  text-transform: uppercase;
  letter-spacing: 1.2px;

  color: white;

  display: flex;
  align-items: center;

  .title-count {
    color: #B9BBBE;

    margin-left: 4px;
    font-size: 12px;

    margin-bottom: -2px;
  }
}

.page-container {
  max-width: 1500px;
  margin: 0 auto;

  padding-top: 32px;
}

@media screen and (max-width: 1500px) {
  .page-container {
      max-width: 1225px;
  }
}

@media screen and (max-width: 1225px) {
  .page-container {
      max-width: 900px;
  }
}

@media screen and (max-width: 900px) {
  .page-container {
      max-width: 610px;
  }
}


@media screen and (max-width: 610px) {
}

</style>

